import { AuthStore } from "@agylia/nu-web-auth";

export interface Category {
    id: string;
    name: string;
}

export interface IGetCategoriesResponse {
    items: Category[]
}

export class CategoryAPI {
    constructor(private route: string, private authStore: AuthStore) {

    }

    public async getCategories(): Promise<IGetCategoriesResponse> {
        const url = `${this.route}/nu-ui/ListCatalogueCategories`;

        const requestInfo = {
            method: 'POST',
            body: '{}'
        };

        const authorizedRequest = 
            await this.authStore.addRequestAuthorization(requestInfo);

        const request = fetch(
            url,
            authorizedRequest
        );

        const response = await request;

        if (!response.ok) {
            throw new Error(`Error loading categories.`);
        }

        const json = await response.json();

        return json;
    }
}