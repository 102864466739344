import * as React from 'react';
import { AuthContext } from '../Controls/AgyliaAuthContext';
import { AuthStore } from '../Stores/AuthStore';

export type AccessTokenProvider = () => Promise<string>;

export interface IAgyliaContext {
  route: string;
  getAccessToken: AccessTokenProvider;
  authStore: AuthStore;
}

/**
 * Allows any control on the page in an AgyliaAuthContext to access the current
 * API route and get a valid access token for the current user.
 */
export const useAgyliaAuthContext = () => {
  const authContext = React.useContext(AuthContext);

  return {
    route: authContext.route,
    getAccessToken: authContext.authStore?.getAccessToken,
    authStore: authContext.authStore,
  };
}
