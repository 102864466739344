import { AuthStore } from '@agylia/nu-web-auth';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  CertificationAPI,
  ICertification,
  ISearchCertificationsRequest,
  ISearchCertificationsResponse,
} from '../APIs/CertificationAPI';

export class CertificationStore {
  private certificationApi: CertificationAPI;
  private searchs: Map<string, ISearchCertificationsResponse> = new Map();
  private certifications: Map<number, ICertification> = new Map();

  constructor(route: string, authStore: AuthStore) {
    makeAutoObservable(this);

    this.certificationApi = new CertificationAPI(route, authStore);
  }

  public async search(request: ISearchCertificationsRequest) {
    const response = await this.certificationApi.searchCertifications(request);

    runInAction(() => {
      const filteredItems = response.items.filter(
        (c) => c.name.toLowerCase().indexOf(request.text.toLowerCase()) !== -1,
      );

      this.searchs.set(this.buildKey(request), {
        items: filteredItems,
        total: filteredItems.length,
      });

      for (const certification of response.items) {
        this.certifications.set(certification.id, certification);
      }
    });
  }

  public get totalCount() {
    return this.certifications.size;
  }

  public getCertification(id: number) {
    return this.certifications.get(id) || null;
  }

  public loadSearch(request: ISearchCertificationsRequest) {
    return this.searchs.get(this.buildKey(request)) || null;
  }

  private buildKey(request: ISearchCertificationsRequest): string {
    return JSON.stringify(request);
  }
}
