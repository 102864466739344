import { makeAutoObservable, runInAction } from 'mobx';
import { SearchCatalogueRequest } from '../APIs/CatalogueEntryAPI';
import { CatalogueStore } from '../Stores/CatalogueStore';
import { SingleCatalogueEntryPickerModel } from './SingleCatalogueEntryPickerModel';

export class SingleCatalogueEntryPickerModalModel {
  public selectedItemIds: Set<string> = new Set();
  public activeSearchText: string = '';
  public visibleSearchText: string = '';

  constructor(
    private catalogueStore: CatalogueStore,
    private pickerModel: SingleCatalogueEntryPickerModel,
  ) {
    this.doSearch();

    if (pickerModel.selectedItemId) {
      this.selectedItemIds.add(pickerModel.selectedItemId);
    }

    makeAutoObservable(this);
  }

  public async doSearch() {
    this.activeSearchText = this.visibleSearchText;

    this.isLoading = true;

    await this.catalogueStore.search(this.buildQuery());

    runInAction(() => {
      this.isLoading = false;
    });
  }

  public clickItem(id: string): void {
    if (this.selectedItemIds.has(id)) {
      this.selectedItemIds.delete(id);
    } else {
      //       if (this.pickerModel.options.singleSelect) {
      this.selectedItemIds.clear();
      //      }

      this.selectedItemIds.add(id);
    }
  }

  public updateVisibleSearchText(text: string) {
    this.visibleSearchText = text;
  }

  public saveDialog(): void {
    this.pickerModel.updateItems(this.selectedItemIds);
    this.hideDialog();
  }

  public hideDialog(): void {
    this.pickerModel.hideDialog();
  }

  public isLoading: boolean = false;

  public get items() {
    return this.catalogueStore.loadSearch(this.buildQuery())?.items || [];
  }

  public get totalItems() {
    return this.catalogueStore.loadSearch(this.buildQuery())?.total || 0;
  }

  public get emptyText() {
    if (this.isLoading) {
      return 'Loading...';
    }
    return this.activeSearchText.length ? 'No items found' : 'No items visible';
  }

  get isDisabled() {
    return false;
  }

  private buildQuery(): SearchCatalogueRequest {
    return {
      context: {
        roles: this.pickerModel.options.roles,
      },
      filter: {
        term: this.activeSearchText,
        type: '',
        category_id: '',
      },
      sort: {
        property: '',
        ascending: true,
      },
      page: {
        limit: 50,
        offset: 0,
      },
    };
  }
}
