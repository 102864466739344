import { AuthStore } from "@agylia/nu-web-auth";

export interface CatalogueItem {
    id: string;
    name: string;
    description: string;
    type: string;
    thumbnail_url: string;
    pass_score: number;
    created_on: string;
}

export interface CatalogueItemCategory {
    id: string;
    name: string;
    thumbnail_url: string;
}

export interface CatalogueItemType {
    name: string;
    type: string;
}

export interface SearchCatalogueRequest {
    context: {
        roles: string[];
    };
    filter: {
        term: string;
        category_id: string;
        type: string;
    };
    sort: {
        property: string;
        ascending: boolean;
    };
    page: {
        limit: number;
        offset: number;
    }
}

export interface SearchCatalogueResponse {
    items: CatalogueItem[],
    total: number
}

export interface GetCatalogueTypesResponse {
    items: CatalogueItemType[],
    total: number;
}

export interface ResolveCatalogueItemsRequest {
    ids: string[];
}

export interface ResolveCatalogueItemsResponse {
    items: { [id: string]: CatalogueItem }
}

export class CatalogueEntryAPI {
    constructor(private route: string, private authStore: AuthStore) {

    }

    public async searchCatalogue(searchRequest: SearchCatalogueRequest): Promise<SearchCatalogueResponse> {
        const url = `${this.route}/nu-ui/SearchCatalogue`;

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(searchRequest)
        };

        const authorizedRequest = 
            await this.authStore.addRequestAuthorization(requestInfo);

        const response = await fetch(
            url,
            authorizedRequest
        );;

        if (!response.ok) {
            throw new Error(`Error searching catalogue.`);
        }

        const json = await response.json();

        return json;
    }

    public async listCatalogueTypes(): Promise<GetCatalogueTypesResponse> {
        const url = `${this.route}/nu-ui/ListCatalogueTypes`;

        const requestInfo = {
            method: 'POST',
            body: '{}'
        }

        const authorizedRequest = 
            await this.authStore.addRequestAuthorization(requestInfo);

        const req = fetch(
            url,
            authorizedRequest);

        const response = await req;

        if (!response.ok) {
            throw new Error(`Error searching catalogue.`);
        }

        const json = await response.json();

        return json;
    }

    public async resolveCatalogueItems(resolveRequest: ResolveCatalogueItemsRequest): Promise<ResolveCatalogueItemsResponse> {
        const url = `${this.route}/nu-ui/ResolveCatalogueItems`;

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(resolveRequest)
        };

        const authorizedRequest = 
            await this.authStore.addRequestAuthorization(requestInfo);

        const response = await fetch(
            url,
            authorizedRequest
        );

        if (!response.ok) {
            throw new Error(`Error searching catalogue.`);
        }

        const json = await response.json();

        return json;
    }
}