import { decode } from 'jsonwebtoken';
import { AuthAPI } from "../APIs/AuthAPI";

/**
 * A store to handle token refresh and caching.
 */
export class AuthStore {
  private refreshToken: string | null = null;
  private accessToken: string | null = null;

  private authAPI: AuthAPI;

  /**
   * 
   * @param route - The root API gateway this API is hosted behind with scheme, e.g. https://apigateway.cmbackbone.net
   * @param initialRefreshToken - A valid refresh token for the current user
   */
  constructor(route: string, initialRefreshToken: string | null) {
    this.refreshToken = initialRefreshToken;
    this.authAPI = new AuthAPI(route);

    this.getAccessToken = this.getAccessToken.bind(this);
  }

  /**
   * Get a valid access token for the current user. This may make a request to the auth
   * backend to refresh the access token, so the token returned from this should not be 
   * cached by the client as it may go stale.
   */
  public async getAccessToken(): Promise<string> {
    if (this.accessToken &&
      this.isTokenValid(this.accessToken)) {
      return this.accessToken;
    }

    if (this.refreshToken === null) {
      throw new Error('Unable to get access token without a refresh token.');
    }

    // TODO: Cache this promise so we're only ever making a single request
    // for this.
    const response = await this.authAPI.reissueAccessToken(this.refreshToken);

    this.accessToken = response?.access_token;
    this.refreshToken = response?.refresh_token;

    return this.accessToken;
  }

  public async addRequestAuthorization(requestInfo: RequestInit): Promise<RequestInit> {
    // If this is explicitly null, auth is being handled externally, so do nothing
    if (this.refreshToken === null) {
      return requestInfo;
    }

    const token = await this.getAccessToken();

    requestInfo.headers = 
      Object.assign(
        {},
        requestInfo.headers,
        {
          authorization: `Bearer ${token}`,
        },
      );

    return requestInfo;
  }

  private isTokenValid(token: string): boolean {
    const decoded = decode(token, {complete: true});

    if (
      decoded === null || 
      typeof decoded === "string") {
      return false;
    }
    
    const currentDate = Math.floor(Date.now() / 1000);
    const payload = decoded.payload;
    const exp = payload.exp;

    if (exp < currentDate) {
      return false;
    }

    return true;
  }
}
