import * as React from 'react';
import { AgyliaUIContext } from '../Controls/AgyliaUIContext';

export const useAgyliaUIContext = () => {
  const context = React.useContext(AgyliaUIContext);

  if (
    !context.catalogueStore ||
    !context.categoryStore ||
    !context.certificationStore
  ) {
    throw new Error('Attempted to useAgyliaUIContext without valid context.');
  }

  return {
    categoryStore: context.categoryStore,
    catalogueStore: context.catalogueStore,
    certificationStore: context.certificationStore,
  };
};
