import { makeAutoObservable } from 'mobx';
import { ISingleCertificationPickerProps } from '../single-certification-picker';
import { CertificationStore } from '../Stores/CertificationStore';
import { SingleCertificationPickerModalModel } from './SingleCertificationPickerModalModel';

export class SingleCertificationPickerModel {
  selectedItemId: number | null;
  dialogModel: SingleCertificationPickerModalModel | null = null;
  isReadonly: boolean;

  constructor(
    private store: CertificationStore,
    public options: ISingleCertificationPickerProps,
  ) {
    this.selectedItemId = options.selectedCertificationId
      ? parseInt(options.selectedCertificationId)
      : null;
    this.isReadonly = !!options.isReadonly;

    makeAutoObservable(this);
  }

  public updateItems(ids: Set<number>) {
    if (ids.size > 0) {
      this.selectedItemId = Array.from(ids)[0];
    } else {
      this.selectedItemId = null;
    }

    this.options.onChange(
      this.selectedItemId ? this.selectedItemId.toString() : null,
      (this.selectedItemId !== null &&
        this.store.getCertification(this.selectedItemId)?.name) ||
        null,
    );
  }

  public showModal() {
    this.dialogModel = new SingleCertificationPickerModalModel(
      this.store,
      this,
    );
  }

  public hideModal() {
    this.dialogModel = null;
  }

  public get displayText() {
    if (this.selectedItemId === null) {
      return 'No certification selected';
    } else {
      const selectedItem = this.store.getCertification(this.selectedItemId);

      if (selectedItem) {
        return selectedItem.name;
      } else {
        this.store.search({
          text: '',
        });

        return 'Loading...';
      }
    }
  }
}
