import { AuthStore } from "@agylia/nu-web-auth";
import { makeAutoObservable } from "mobx";
import { Category, CategoryAPI } from "../APIs/CategoryAPI";

export class CategoryStore {
    private categoryAPI: CategoryAPI;

    public categories: Category[] = [];

    public isLoading: boolean = false;

    constructor(route: string, authStore: AuthStore) {
        makeAutoObservable(this);

        this.categoryAPI = new CategoryAPI(route, authStore);
    }

    public async fetchCategories(): Promise<void> {
        this.isLoading = true;

        this.categories = (await this.categoryAPI.getCategories()).items;

        this.isLoading = false;
    }
}