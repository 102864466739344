import { makeAutoObservable } from "mobx";
import { ISingleCatalogueEntryPickerProps } from "../single-catalogue-entry-picker";
import { CatalogueStore } from "../Stores/CatalogueStore";
import { SingleCatalogueEntryPickerModalModel } from "./SingleCatalogueEntryPickerModalModel";

export class SingleCatalogueEntryPickerModel {
  selectedItemId: string | null = null;
  isReadonly: boolean;

  dialogModel: SingleCatalogueEntryPickerModalModel | null = null;

  constructor(private catalogueStore: CatalogueStore, public options: ISingleCatalogueEntryPickerProps) {
    this.selectedItemId = options.selectedItemId || null;
    this.isReadonly = !!options.isReadonly;

    makeAutoObservable(this);
  }

  public updateItems(ids: Set<string>) {
    if (ids.size > 0) {
      // TODO: This is kinda ugly
      this.selectedItemId = Array.from(ids)[0];
    } else {
      this.selectedItemId = null;
    }

    this.options.onChange(
      this.selectedItemId,
      this.selectedItemId !== null &&
        this.catalogueStore.getItem(this.selectedItemId)?.name ||
        null
    );
  }

  public showModal() {
    this.dialogModel = new SingleCatalogueEntryPickerModalModel(this.catalogueStore, this);
  }

  public hideDialog() {
    this.dialogModel = null;
  }

  public get displayText() {
    if (this.selectedItemId === null) {
      return 'No item selected';
    } else {
      const selectedItem = this.catalogueStore.getItem(this.selectedItemId);

      if (selectedItem) {
        return selectedItem.name;
      } else {
        this.catalogueStore.resolveItem(this.selectedItemId);

        return 'Loading...';
      }
    }
  }
}