import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { SingleCategoryPickerModalModel } from '../Models/SingleCategoryPickerModalModel';

export interface ISingleCategoryPickerModalProps {
  model: SingleCategoryPickerModalModel;
}

export const SingleCategoryPickerModal: React.FC<ISingleCategoryPickerModalProps> = observer(
  ({ model }) => {
    return (
      <Modal show onHide={() => model.onCancel()}>
        <Modal.Header closeButton>Catalogue categories</Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush" style={{ paddingTop: 5 }}>
            {model.categories &&
              model.categories.map((t) => (
                <ListGroup.Item 
                    key={t.id} 
                    action 
                    active={t.id === model.selectedCategoryId}
                    onClick={() => model.selectCategory(t.id)}>
                  <h5>{t.name}</h5>
                </ListGroup.Item>
              ))}
            {!model.categories && <span>Loading...</span>}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => model.onCancel()} variant="secondary">Cancel</Button>
          <Button onClick={() => model.onSave()} variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>
    );
  },
);

SingleCategoryPickerModal.displayName = 'SingleCategoryPickerModal';
