import { makeAutoObservable } from "mobx";
import { CategoryStore } from "../Stores/CategoryStore";
import { SingleCategoryPickerModel } from "./SingleCategoryPickerModel";

export class SingleCategoryPickerModalModel {
    public selectedCategoryId: string | null = null;

    constructor(private categoryStore: CategoryStore, private pickerModel: SingleCategoryPickerModel) {
        makeAutoObservable(this);

        this.selectedCategoryId = pickerModel.selectedCategoryId;
    }

    public async onOpen() {
        await this.categoryStore.fetchCategories();
    }

    public onSave() {
        this.pickerModel.selectCategory(this.selectedCategoryId);
        this.pickerModel.closeDialog();
    }

    public onCancel() {
        this.pickerModel.closeDialog();
    }

    public selectCategory(categoryId: string) {
        this.selectedCategoryId = categoryId;
    }

    public get categories() {
        return this.categoryStore.categories;
    }
}
