import { AgyliaAuthContext, useAgyliaAuthContext } from '@agylia/nu-web-auth';
import * as React from 'react';
import { CatalogueStore } from '../Stores/CatalogueStore';
import { CategoryStore } from '../Stores/CategoryStore';
import { CertificationStore } from '../Stores/CertificationStore';

export interface IAgyliaAuthProps {
  refreshToken: string;
  route: string;
  children: React.ReactNode;
}

interface IAgyliaUIContext {
  route: string | null;
  categoryStore: CategoryStore | null;
  catalogueStore: CatalogueStore | null;
  certificationStore: CertificationStore | null;
}

export const AgyliaUIContext = React.createContext<IAgyliaUIContext>({
  route: '',
  categoryStore: null,
  catalogueStore: null,
  certificationStore: null,
});

AgyliaUIContext.displayName = 'AgyliaUIContext';

export const AgyliaContext: React.FC<IAgyliaAuthProps> = ({
  children,
  refreshToken,
  route,
}) => {
  return (
    <AgyliaAuthContext refreshToken={refreshToken} route={route}>
      <AgyliaContextInner>{children}</AgyliaContextInner>
    </AgyliaAuthContext>
  );
};

AgyliaContext.displayName = 'AgyliaContext';

const AgyliaContextInner: React.FC<{}> = ({ children }) => {
  const authContext = useAgyliaAuthContext();

  if (!authContext.authStore || !authContext.route) {
    throw new Error(
      'AgyliaContextInner must be created in a valid AgyliaAuthContext',
    );
  }

  const agyliaUIContext: IAgyliaUIContext = {
    route: authContext.route,
    categoryStore: new CategoryStore(authContext.route, authContext.authStore),
    catalogueStore: new CatalogueStore(
      authContext.route,
      authContext.authStore,
    ),
    certificationStore: new CertificationStore(
      authContext.route,
      authContext.authStore,
    ),
  };

  return (
    <AgyliaUIContext.Provider value={agyliaUIContext}>
      {children}
    </AgyliaUIContext.Provider>
  );
};
