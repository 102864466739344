import { makeAutoObservable, runInAction } from 'mobx';
import { CertificationStore } from '../Stores/CertificationStore';
import { SingleCertificationPickerModel } from './SingleCertificationPickerModel';

export class SingleCertificationPickerModalModel {
  public isLoading: boolean = false;
  public selectedItemIds: Set<number> = new Set();
  public activeSearchText: string = '';
  public visibleSearchText: string = '';

  constructor(
    private certificationStore: CertificationStore,
    private model: SingleCertificationPickerModel,
  ) {
    this.doSearch();

    if (model.selectedItemId) {
      this.selectedItemIds.add(model.selectedItemId);
    }

    makeAutoObservable(this);
  }

  public clickItem(id: number) {
    console.log(`Item ${id} clicked`);

    if (this.selectedItemIds.has(id)) {
      this.selectedItemIds.delete(id);
    } else {
      this.selectedItemIds.clear();
      this.selectedItemIds.add(id);
    }
  }

  public async doSearch() {
    this.isLoading = true;
    this.activeSearchText = this.visibleSearchText;

    await this.certificationStore.search({
      text: this.activeSearchText,
    });

    runInAction(() => {
      this.isLoading = false;
    });
  }

  public hideDialog() {
    this.model.hideModal();
  }

  public saveDialog() {
    this.model.updateItems(this.selectedItemIds);

    this.hideDialog();
  }

  public updateVisibleSearchText(text: string) {
    this.visibleSearchText = text;
  }

  public get items() {
    return (
      this.certificationStore.loadSearch({
        text: this.activeSearchText,
      })?.items || []
    );
  }

  public get totalItemCount() {
    return this.certificationStore.totalCount;
  }

  public get emptyText() {
    if (this.isLoading) {
      return 'Loading...';
    }
    return 'No items visible';
  }

  public get isDisabled() {
    return false;
  }
}
