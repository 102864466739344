import * as React from 'react';

import { Button } from 'react-bootstrap';

import { SingleCatalogueEntryPickerModel } from './Models/SingleCatalogueEntryPickerModel';
import { useState } from 'react';
import { CatalogueEntryPickerModal } from './Controls/CatalogueEntryPickerModal';
import { observer } from 'mobx-react';
import { useAgyliaUIContext } from './Hooks/useAgyliaUIContext';

export interface ISingleCatalogueEntryPickerProps {
  isReadonly?: boolean;
  includeDeletedItems?: boolean;
  selectedItemId: string | null;
  roles: string[];
  onChange: (id: string | null, title: string | null) => void;
}

interface ISingleCatalogueEntyPickerImplProps {
  model: SingleCatalogueEntryPickerModel;
}

const SingleCatalogueEntryPicker: React.FC<ISingleCatalogueEntyPickerImplProps> = observer(
  ({ model }) => {
    return (
      <div className="">
        <Button disabled={model.isReadonly} onClick={() => model.showModal()}>
          Open
        </Button>

        <span style={{ paddingLeft: 20 }}>{model.displayText}</span>

        {model.dialogModel && (
          <CatalogueEntryPickerModal model={model.dialogModel} />
        )}
      </div>
    );
  },
);

SingleCatalogueEntryPicker.displayName = 'SingleCatalogueEntryPicker';

export const SingleCatalogueEntryPickerWrapper: React.FC<ISingleCatalogueEntryPickerProps> = (
  props: ISingleCatalogueEntryPickerProps,
) => {
  const { catalogueStore } = useAgyliaUIContext();

  if (!catalogueStore) {
    throw new Error(
      'Attempted to use SingleCatalogueEntryPicker without a valid AgyliaUIContext.',
    );
  }

  const [model] = useState(
    new SingleCatalogueEntryPickerModel(catalogueStore, props),
  );

  return <SingleCatalogueEntryPicker model={model} />;
};

SingleCatalogueEntryPickerWrapper.displayName =
  'SingleCatalogueEntryPickerWrapper';
