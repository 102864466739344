import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { CatalogueItem } from '../APIs/CatalogueEntryAPI';
import { SingleCatalogueEntryPickerModalModel } from '../Models/SingleCatalogueEntryPickerModalModel';

import './CatalogueEntryPickerModal.css';
import { TextFilter } from './TextFilter';

interface ICatalogueEntryPickerModalProps {
  model: SingleCatalogueEntryPickerModalModel;
}

interface ICatalogueEntryPickerEntryProps {
  item: CatalogueItem | null;
  onClick: () => void;
}

/*
const CategoryFilter: React.FC<{}> = () => {
  return <></>;
};
*/

/*
const TypeFilter: React.FC<{}> = () => {
  return <></>;
};
*/

const Selections: React.FC<{}> = () => {
  return <></>;
};

const Sort: React.FC<{}> = () => {
  return <></>;
};

const CatalogueEntryThumbnail: React.FC<ICatalogueEntryPickerEntryProps> = ({
  item,
}) => {
  if (item) {
    return (
      <div className="nu-picker-item-thumb">
        <img src={item.thumbnail_url} />
      </div>
    );
  } else {
    return <span />;
  }
};

const CatalogueEntryBody: React.FC<ICatalogueEntryPickerEntryProps> = ({
  item,
}) => {
  if (item) {
    // TODO: Remove markdown from description
    return (
      <div className="nu-picker-item-data">
        <div className="nu-picker-item-text truncate-single-line">
          <strong>{item.name}</strong>
        </div>
        <div className="nu-picker-item-text truncate-three-line">
          <small>{item.description || ''}</small>
        </div>
      </div>
    );
  } else {
    return (
      <div className="title">
        <strong>Unknown catalogue item</strong>
      </div>
    );
  }
};

const Items: React.FC<ICatalogueEntryPickerModalProps> = observer(
  ({ model }) => {
    const preselected = false;

    return (
      <ul className="nu-picker-items scrollable">
        {model.items.length === 0 && model.emptyText}
        {model.items.map((i) => {
          const selected = model.selectedItemIds.has(i.id);
          const onClick = () => {
            model.clickItem(i.id);
          };
          const newLabel =
            new Date(i.created_on).toDateString() ===
            new Date().toDateString() ? (
              <span className="label label-success">New</span>
            ) : (
              ''
            );

          return (
            <li
              className="nu-picker-item nu-clickable"
              onClick={onClick}
              key={i.id}
            >
              <input
                type="checkbox"
                readOnly={preselected || model.isDisabled}
                checked={selected || preselected}
              />
              <CatalogueEntryThumbnail item={i} onClick={onClick} />
              <CatalogueEntryBody item={i} onClick={onClick} />
              <div className="nu-picker-item-icons">
                <span className="nu-picker-item-label">{i.type}</span>
                {newLabel}
              </div>
            </li>
          );
        })}
      </ul>
    );
  },
);

Items.displayName = 'Items';

const ItemsSummary: React.FC<ICatalogueEntryPickerModalProps> = observer(
  ({ model }) => {
    return (
      <div className="nu-picker-summary">
        <small>
          Showing <strong>{model.items.length}</strong> of{' '}
          <strong>{model.totalItems}</strong>
        </small>
      </div>
    );
  },
);

ItemsSummary.displayName = 'ItemsSummary';

export const CatalogueEntryPickerModal: React.FC<ICatalogueEntryPickerModalProps> = observer(
  ({ model }) => {
    /*
    <div className="filter col-xs-3">
              <CategoryFilter />
            </div>
            <div className="filter col-xs-3">
              <TypeFilter />
            </div>
            */
    return (
      <Modal
        show
        onHide={() => model.hideDialog()}
        className="nu-picker-dialog"
      >
        <Modal.Header>Catalogue entries</Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="nu-picker-filters">
              <Col md={12}>
                <TextFilter model={model} />
              </Col>
            </Row>
            <div className="picker-actions row">
              <div className="col-xs-5" />
              <div className="action col-xs-3">
                <Selections />
              </div>
              <div className="action col-xs-4">
                <Sort />
              </div>
            </div>
            <div className="row spacer">
              <div className="col-xs-12">
                <Items model={model} />
              </div>
            </div>
            <Row>
              <Col md={12}>
                <ItemsSummary model={model} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => model.saveDialog()}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => model.hideDialog()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  },
);

CatalogueEntryPickerModal.displayName = 'CatalogueEntryPickerModal';
