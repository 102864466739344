import * as React from 'react';

import { Button } from 'react-bootstrap';

import { useState } from 'react';
import { useAgyliaUIContext } from './Hooks/useAgyliaUIContext';
import { SingleCategoryPickerModel } from './Models/SingleCategoryPickerModel';
import { SingleCategoryPickerModal } from './Controls/SingleCategoryPickerModal';
import { observer } from 'mobx-react';
import { SingleCatalogueEntryPickerWrapper } from './single-catalogue-entry-picker';

export interface ISingleCategoryEntryPickerProps {
  isReadonly?: boolean;
  selectedItemId: string | null;
  setSelectedItemId: (categoryId: string | null) => void;
}

interface ISingleCategoryEntyPickerImplProps {
  model: SingleCategoryPickerModel;
}

const SingleCategoryPicker: React.FC<ISingleCategoryEntyPickerImplProps> = observer(
  ({ model }) => {
    return (
      <div className="">
        <Button disabled={model.isReadonly} onClick={() => model.onOpen()}>
          Open
        </Button>
        &nbsp;
        {model.displayText}
        {model.dialogModel && (
          <SingleCategoryPickerModal model={model.dialogModel} />
        )}
      </div>
    );
  },
);

SingleCategoryPicker.displayName = 'SingleCategoryPicker';

export const SingleCategoryEntryPickerWrapper: React.FC<ISingleCategoryEntryPickerProps> = (
  props,
) => {
  const { categoryStore } = useAgyliaUIContext();

  if (!categoryStore) {
    throw new Error(
      'Attempted to use SingleCategoryEntryPickerWrapper without a valid AgyliaUIContext.',
    );
  }

  const [model] = useState(new SingleCategoryPickerModel(categoryStore, props));

  return <SingleCategoryPicker model={model} />;
};

SingleCatalogueEntryPickerWrapper.displayName =
  'SingleCatalogueEntryPickerWrapper';
