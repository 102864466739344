import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { ICertification } from '../APIs/CertificationAPI';

import { SingleCertificationPickerModalModel } from '../Models/SingleCertificationPickerModalModel';
import { TextFilter } from './TextFilter';

export interface ICertificationPickerModalProps {
  model: SingleCertificationPickerModalModel;
}

interface ICertificationEntryProps {
  item: ICertification;
  onClick: () => void;
}

const CertificationBody: React.FC<ICertificationEntryProps> = ({ item }) => {
  if (item) {
    return (
      <div className="nu-picker-item-data">
        <div className="nu-picker-item-text truncate-single-line">
          <strong>{item.name}</strong>
        </div>
        <div className="nu-picker-item-text truncate-three-line">
          <small>{item.description || ''}</small>
        </div>
      </div>
    );
  } else {
    return (
      <div className="title">
        <strong>Unknown certification</strong>
      </div>
    );
  }
};

CertificationBody.displayName = 'CertificationBody';

const Items: React.FC<ICertificationPickerModalProps> = observer(
  ({ model }) => {
    const preselected = false;

    return (
      <ul className="nu-picker-items scrollable">
        {model.items.length === 0 && model.emptyText}
        {model.items.map((i) => {
          const selected = model.selectedItemIds.has(i.id);
          const onClick = () => {
            model.clickItem(i.id);
          };

          return (
            <li
              className="nu-picker-item nu-clickable"
              onClick={onClick}
              key={i.id}
            >
              <input
                type="checkbox"
                readOnly={preselected || model.isDisabled}
                checked={selected || preselected}
              />
              <CertificationBody item={i} onClick={onClick} />
            </li>
          );
        })}
      </ul>
    );
  },
);

Items.displayName = 'Items';

const ItemsSummary: React.FC<ICertificationPickerModalProps> = observer(
  ({ model }) => {
    return (
      <div className="nu-picker-summary">
        <small>
          Showing <strong>{model.items.length}</strong> of{' '}
          <strong>{model.totalItemCount}</strong> certifications
        </small>
      </div>
    );
  },
);

ItemsSummary.displayName = 'ItemsSummary';

export const CertificationPickerModal: React.FC<ICertificationPickerModalProps> = observer(
  ({ model }) => {
    return (
      <Modal
        show
        onHide={() => model.hideDialog()}
        className="nu-picker-dialog"
      >
        <Modal.Header>Certifications</Modal.Header>
        <Modal.Body>
          {' '}
          <Container>
            <Row>
              <Col xs={12}>
                <TextFilter model={model} />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Items model={model} />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ItemsSummary model={model} />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => model.saveDialog()}>
            Save
          </Button>
          <Button variant="secondary" onClick={() => model.hideDialog()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  },
);
