import { makeAutoObservable } from "mobx";
import { Category } from "../APIs/CategoryAPI";
import { ISingleCategoryEntryPickerProps } from "../single-category-picker";
import { CategoryStore } from "../Stores/CategoryStore";
import { SingleCategoryPickerModalModel } from "./SingleCategoryPickerModalModel";

export class SingleCategoryPickerModel {
    public showModal: boolean = false;
    public categories: Category[] | null = null;
    public selectedCategoryId: string | null = null;
    public isReadonly: boolean;

    public dialogModel: SingleCategoryPickerModalModel | null = null;

    constructor(private categoryStore: CategoryStore, private props: ISingleCategoryEntryPickerProps) {
        makeAutoObservable(this);

        if (props.selectedItemId) {
            this.selectedCategoryId = props.selectedItemId;

            this.categoryStore.fetchCategories();
        }

        this.isReadonly = !!props.isReadonly;
    }

    public async onOpen() {
        this.dialogModel = new SingleCategoryPickerModalModel(
            this.categoryStore,
            this
        );

        await this.dialogModel.onOpen();
    }

    public selectCategory(categoryId: string | null) {
        this.selectedCategoryId = categoryId;
    }

    public closeDialog() {
        this.dialogModel = null;

        this.props.setSelectedItemId(this.selectedCategoryId);
    }

    public get displayText() {
        if (this.selectedCategoryId === null) {
            return 'No category selected';
        } 

        const selectedCategory = this.categoryStore.categories.find(c => c.id ===this.selectedCategoryId);

        if (!selectedCategory && this.categoryStore.isLoading) {
            return 'Loading...'
        }

        if (!selectedCategory) {
            return 'Unknown category';
        }

        return selectedCategory.name;
    }
}