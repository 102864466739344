import { AuthStore } from '@agylia/nu-web-auth';

export interface ICertification {
  id: number;
  name: string;
  description: string;
}

export interface ISearchCertificationsRequest {
  text: string;
}

export interface ISearchCertificationsResponse {
  items: ICertification[];
  total: number;
}

export class CertificationAPI {
  constructor(private route: string, private authStore: AuthStore) {}

  public async searchCertifications(
    request: ISearchCertificationsRequest,
  ): Promise<ISearchCertificationsResponse> {
    const url = `${this.route}/nu-ui/SearchCertifications`;

    const requestInfo = {
      method: 'POST',
      body: JSON.stringify(request),
    };

    const authorizedRequest = await this.authStore.addRequestAuthorization(
      requestInfo,
    );

    const response = await fetch(url, authorizedRequest);

    if (!response.ok) {
      throw new Error(`Error searching certifications`);
    }

    const json = await response.json();

    return json;
  }
}
