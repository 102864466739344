import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { MdSearch } from 'react-icons/md';

export interface ITextFilterProps {
  model: {
    visibleSearchText: string;
    updateVisibleSearchText: (s: string) => void;
    doSearch: () => void;
  };
}

export const TextFilter: React.FC<ITextFilterProps> = observer(({ model }) => {
  return (
    <InputGroup>
      <input
        className="form-control search"
        type="text"
        placeholder="Search"
        value={model.visibleSearchText}
        onChange={(e) => {
          model.updateVisibleSearchText(e.currentTarget.value);
        }}
        onKeyDown={(e) => {
          if (e.keyCode == 13) {
            model.doSearch();
          }
        }}
      />
      <InputGroup.Append>
        <Button className="search-button" onClick={() => model.doSearch()}>
          <MdSearch />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
});
