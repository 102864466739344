import { AuthStore } from '@agylia/nu-web-auth';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  CatalogueEntryAPI,
  CatalogueItem,
  SearchCatalogueRequest,
  SearchCatalogueResponse,
} from '../APIs/CatalogueEntryAPI';

export class CatalogueStore {
  private catalogueEntryAPI: CatalogueEntryAPI;
  private searchs: Map<string, SearchCatalogueResponse> = new Map();
  private catalogueItems: Map<string, CatalogueItem> = new Map();

  constructor(route: string, authStore: AuthStore) {
    makeAutoObservable(this);

    this.catalogueEntryAPI = new CatalogueEntryAPI(route, authStore);
  }

  public getItem(id: string) {
    return this.catalogueItems.get(id);
  }

  public async resolveItem(id: string) {
    const response = await this.catalogueEntryAPI.resolveCatalogueItems({
      ids: [id],
    });

    runInAction(() => {
      for (const responseId of Object.keys(response.items)) {
        this.catalogueItems.set(responseId, response.items[responseId]);
      }
    });
  }

  public async search(request: SearchCatalogueRequest) {
    const response = await this.catalogueEntryAPI.searchCatalogue(request);

    runInAction(() => {
      this.searchs.set(this.buildKey(request), response);

      for (const item of response.items) {
        this.catalogueItems.set(item.id, item);
      }
    });
  }

  public loadSearch(
    request: SearchCatalogueRequest,
  ): SearchCatalogueResponse | null {
    return this.searchs.get(this.buildKey(request)) || null;
  }

  private buildKey(request: SearchCatalogueRequest): string {
    return JSON.stringify(request);
  }
}
