import * as React from 'react';

import { Button } from 'react-bootstrap';

import { SingleCertificationPickerModel } from './Models/SingleCertificationPickerModel';
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useAgyliaUIContext } from './Hooks/useAgyliaUIContext';
import { CertificationPickerModal } from './Controls/CertificationPickerModal';

export interface ISingleCertificationPickerProps {
  isReadonly?: boolean;
  selectedCertificationId: string | null;
  onChange: (id: string | null, title: string | null) => void;
}

interface ISingleCertificationPickerImplProps {
  model: SingleCertificationPickerModel;
}

const SingleCertificationPicker: React.FC<ISingleCertificationPickerImplProps> = observer(
  ({ model }) => {
    return (
      <div>
        <Button disabled={model.isReadonly} onClick={() => model.showModal()}>
          Open
        </Button>

        <span style={{ paddingLeft: 20 }}>{model.displayText}</span>

        {model.dialogModel && (
          <CertificationPickerModal model={model.dialogModel} />
        )}
      </div>
    );
  },
);

SingleCertificationPicker.displayName = 'SingleCertificationPicker';

export const SingleCertificationPickerWrapper: React.FC<ISingleCertificationPickerProps> = (
  props: ISingleCertificationPickerProps,
) => {
  const { certificationStore } = useAgyliaUIContext();

  if (!certificationStore) {
    throw new Error(
      'Attempted to use SingleCertificationPicker without a valid AgyliaUIContext.',
    );
  }

  const [model] = useState(
    new SingleCertificationPickerModel(certificationStore, props),
  );

  return <SingleCertificationPicker model={model} />;
};

SingleCertificationPickerWrapper.displayName =
  'SingleCertificationPickerWrapper';
