export interface IIssueTokenRequest {
  scope: string,
  "hostname": string,
    "user_id": string,
    "access_duration": number,
    "refresh_duration": number
}

export interface IIssueTokenResponse {
  access_token: string;
  refresh_token: string;
}

/**
 * Provides a raw API over the nu-web-auth backend using fetch.
 */
export class AuthAPI {
  /**
   * 
   * @param route - The root API gateway this API is hosted behind with scheme, e.g. https://apigateway.cmbackbone.net
   */
  constructor(private route: string) {
     
  }

    /**
   * Given a current, valid, refresh token, get a fresh access and refresh token.
   * 
   * @param key An API Token to authorize your request
   * @param refreshToken The current user's refresh token
   */
  public async issueAccessToken(key: string, request: IIssueTokenRequest): Promise<IIssueTokenResponse> {
    const req = fetch(this.buildIssueUrl(), {
      method: 'POST',
      headers: {
        "x-api-key": key
      },
      body: JSON.stringify(request),
    });

    const response = await req;

    if (!response.ok) {
        throw new Error(`Error requesting token: ${response.status}`);
    }

    const json: IIssueTokenResponse = await response.json();

    return json;
  }

  /**
   * Given a current, valid, refresh token, get a fresh access and refresh token.
   * 
   * @param refreshToken The current user's refresh token
   */
  public async reissueAccessToken(refreshToken: string): Promise<IIssueTokenResponse> {
    const req = fetch(this.buildReissueUrl(), {
      method: 'POST',
      headers: {
        authorization: `Bearer ${refreshToken}`
      },
      body: '{}'
    });

    const response = await req;

    if (!response.ok) {
        throw new Error(`Error requesting token: ${response.status}`);
    }

    const json: IIssueTokenResponse = await response.json();

    return json;
  }

  private buildIssueUrl() {
    return `${this.route}/nu-auth/Issue`;
  }

  private buildReissueUrl() {
    return `${this.route}/nu-auth/Reissue`;
  }
}