import * as React from 'react';
import { AuthStore } from '../Stores/AuthStore';

export interface IAgyliaAuthProps {
  refreshToken: string;
  route: string;
  children: React.ReactNode;
}

interface IAuthContext {
  route: string;
  authStore: AuthStore | null;
}

export const AuthContext = React.createContext<IAuthContext>({
  route: '',
  authStore: null
});

export const AgyliaAuthContext: React.FC<IAgyliaAuthProps> =
  ({ children, refreshToken, route }) => {
    const context = {
      route,
      authStore: new AuthStore(
        route,
        refreshToken),
    };

    return (
      <AuthContext.Provider
        value={context}
        >
        {children}
      </AuthContext.Provider>
    )
  }
